@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: #000;
  font-family: 'Montserrat', sans-serif;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  outline: none;
  border: none;
}

input {
  outline: none;
  border: none;
}

.logo img {
  width: 70px;
}

#main {
  width: 100%;
  height: 900px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./Images/backgroundss.png');
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: transparent;
  transition: 0.6s ease-in-out;
}

nav.active {
  box-shadow: 5px 10px 30px rgba(0, 247, 255, 0.157);
  background-color: #000;
}

nav ul {
  display: flex;
}

.active {
  background-color: #764BA2;
  color: #ffffff;
  box-shadow: 5px 10px 30px rgba(189, 198, 64, 0.411);
  transition: all ease 0.2s;
}

nav ul li a {
  font-family: calibri;
  height: 40px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}

nav ul li a:hover {
  background-color: #764da2;
  color: #ffffff;
  box-shadow: 5px 10px 30px rgba(2, 238, 255, 0.336);
  transition: all ease 0.2s;
}

nav .menu-btn, .menu-icon {
  display: none;
}

.name {
  font-family: 'Montserrat';
  width: 500px;
  position: absolute;
  left: 15%;
  top: 70%;
  transform: translate(-22%, -50%);
}

.name span {
  color: #b90ef7;
}

.name .details {
  color: #fff;
}

.name h1 {
  font-family: 'Montserrat';
  font-size: 40px;
  margin: 0;
  letter-spacing: 2px;
  color: #fff;
}

.header-btns {
  display: flex;
  margin-top: 40px;
}

.cv-btn {
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9d00ff;
  box-shadow: 5px 10px 30px rgba(2, 238, 255, 0.336);
  border-radius: 10px;
  color: #ffffff;
}
.download-btn {
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9d00ff;
  box-shadow: 5px 10px 30px rgba(2, 238, 255, 0.336);
  border-radius: 10px;
  color: #ffffff;
}

.cv-btn:hover {
  background-color: #9d00ff;
  transition: all ease 0.5s;
  color: #ffffff;
}
.download-btn:hover {
  background-color: #9d00ff;
  transition: all ease 0.5s;
  color: #ffffff;
}

.f-heading h1 {
  color: #FFFFFF;
  font-size: 3rem;
  margin: 0;
  padding: 0;
}

.f-heading p {
  color: rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.f-heading {
  text-align: center;
  margin-top: 60px;
  margin-bottom: -80px;
}

#about {
  margin-top: 100px;
  width: 100%;
  background-color: #000;
  padding: 50px 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.about-text {
  width: 70%;
  color: #fff;
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.about-text h2 {
  font-size: 2rem;
  color: #9d00ff;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.about-text p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  line-height: 1.5;
}




.about-image {
  width: 25%;
}

.about-image img {
  width: 100%;
  height: auto;
}

#features {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  background-color: #000;
  overflow-x: auto;
  white-space: nowrap;
}

.a-container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
}

.a-box {
  background-color: #000;
  width: 250px;
  height: 400px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: 0.3s ease-in-out;
  position: relative;
  display: inline-block;
}

.a-b-img {
  width: 100%;
  height: 60%;
}

.a-b-img img {
  padding: 15px;
  width: 100%;
  height: 100%;
}



#presentation {
  padding-top: 30px;
  width: 100%;
  height: 768px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(./Images/presentationf.png);
  text-align: center;
}
#loginbg { 
  background-image: './loginbg.jpg';
   background-size: 'cover';
  background-repeat: 'no-repeat';
   min-height: '100vh';
  color: 'white';
 };

#presentation h1 {
  font-size: 2rem;
  color: #9d00ff;
}

#services {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.s-heading h1 {
  color: #FFFFFF;
  font-size: 3rem;
  margin: 0;
  padding: 0;
}

.s-heading p {
  color: rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.s-heading {
  text-align: center;
  margin: 20px 0;
}

.s-box {
  background-color: #000;
  width: 350px;
  height: 470px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.b-container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.s-b-img {
  width: 100%;
  height: 50%;
}

.s-b-img img {
  width: 100%;
  height: 100%;
}

.s-b-text {
  width: 100%;
  height: 40%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  bottom: -100%;
  transition: bottom 0.5s ease-in-out;
  color: #9d00ff;
}

.s-b-text p {
  margin: 0;
  color: #fff;
  font-size: 1.1rem;
  font-family: calibri;
  display: block;
  display: -webkit-box;
  max-width: 80%;
  margin-top: 25px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.s-b-text p:hover {
  color: #000266;
}

.s-b-text a {
  margin-top: 15px;
}

.s-type {
  font-family: calibri;
  color: #000266;
  background-color: #000;
  padding: 10px 15px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  border-radius: 0 0 10px 10px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
}

#contact {
  background-color: #9d00ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

#contact h3 {
  font-size: 2.5rem;
  margin: 20px;
  color: #fff;
}

.contact-input {
  width: 280px;
  height: 50px;
  background-color: #000;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
}

.contact-input input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  color: #fff;
}

.contact-input a {
  width: 200px;
  height: 35px;
  background-color: #9d00ff;
  color: #FFFFFF;
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  margin: auto 10px;
  border-radius: 20px;
  font-weight: 500;
  transition: 0.4s ease-out;
}

.contact-input a:hover {
  background-color: #9d00ff;
}

@media (max-width: 1190px) {
  #main {
    background-size: 950px !important;
  }
  .name {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .about-image img {
    height: 400px;
  }
  #features {
    height: auto;
  }
  .a-container {
    flex-wrap: wrap;
  }
  .a-box {
    flex-grow: 1;
  }
  #services {
    height: auto;
  }
  .b-container {
    flex-wrap: wrap;
  }
  .s-box {
    flex-grow: 1;
  }
  .s-b-img img {
    object-fit: cover;
  }
  .a-b-img img {
    object-fit: contain;
  }
}

@media (max-width: 970px) {
  .main {
    background-image: none !important;
  }
  .name {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .about-image {
    height: 200px;
  }
  .cv-btn {
    margin-left: 27%;
    margin-top: 20px;
  }
  .download-btn {
    margin-top: 20px;
  }
  #about {
    justify-content: center;
    padding-top: 20px;
    text-align: center;
    margin-top: 0;
  }
  .about-text {
    width: 70%;
  }
  .about-text h1 {
    font-size: 4rem;
  }
  .about-text p {
    width: 100%;
  }
  #features {
    margin-bottom: 100px;
  }
  #presentation {
    padding-top: 10px;
    width: 380px;
    height: 230px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(./Images/presentationf.png);
    text-align: center;
  }
}

@media (max-width: 600px) {
  .name {
    width: 60%;
    margin-top: 30%;
  }
  .scroll {
    left: 42.5%;
  }
}

@media (max-width: 1100px) {
  .menu-icon {
    display: block;
  }
  nav {
    justify-content: space-between;
    height: 65px;
    padding: 0px 30px;
  }
  .logo img {
    width: 40px;
  }
  .header-btns {
    margin: 0;
  }
  .menu {
    display: none;
    position: absolute;
    top: 65px;
    left: 0;
    background-color: #000;
    border-bottom: 4px solid #1db096;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .menu li {
    width: 100%;
  }
  nav .menu li a {
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 25px;
    border: 1px solid rgba(38, 38, 38, 0.03);
  }
  nav .menu-icon {
    cursor: pointer;
    float: right;
    padding: 10px 10px;
    position: relative;
    user-select: none;
  }
  nav .menu-icon .nav-icon {
    background-color: #000;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }
  nav .menu-icon .nav-icon:before,
  nav .menu-icon .nav-icon:after {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all ease-out 0.2s;
    width: 100%;
  }
  nav .menu-icon .nav-icon:before {
    top: 5px;
  }
  nav .menu-icon .nav-icon:after {
    top: -5px;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon {
    background: transparent;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon:before {
    transform: rotate(-45deg);
    top: 0;
  }
  nav .menu-btn:checked ~ .menu-icon .nav-icon:after {
    transform: rotate(45deg);
    top: 0;
  }
  nav .menu-btn {
    display: none;
  }
  nav .menu-btn:checked ~ .menu {
    display: block;
  }
}

@media (max-width: 400px) {
  .s-box {
    width: 100%;
    height: 400px;
  }
}
